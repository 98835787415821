@import "../base/mixin";

.dashboard{
  background-color: #f7f7f7;
  min-height: 100vh;
  &_top{
    padding: 1rem 5rem;background-color: var(--dark);
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--dark);
    color: #f7f7f7;
    @include respond(tabPort){
      background-color: #e5e5e5;
      padding: 2rem;
      color: #262626;
    }
    &-list{
      display: flex;
      list-style: none;
      justify-content: space-between;
      align-items: center;

      &-item{
        &:not(:last-child){
          margin-right: 1.5rem;
        }
        a{
          text-decoration: none;
          font-size: 1.6rem;
          color: #f7f7f7;
          @include respond(tabPort){
            color: #262626;
          }
        }
      }
    }
  }
}
.dashboard_content{
  display: flex;
  align-items: flex-start;
  //margin-top: 8rem;
  @include respond(tabLand){
    padding: 1rem;
  }
  @include respond(tabPort){
    flex-direction: column;
    position: relative;
    padding: 0;
    align-items: center;
    margin-bottom: 10rem;
    width: 100%;
  }
  .sidebar{

    transition: all .35s ease;
    background-color: var(--dark);
    flex: .12;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 4rem;
    height: 100%;
    min-height: 100vh;
    top: 10rem;
    position: -webkit-sticky;
    position: sticky;
    @include respond(tabPort){
      background-color: var(--dark);
      //border-top: 4px solid #35495e;
      //display: none;
      min-height: auto;
      flex-direction: row;
      backdrop-filter: blur(5px);
      bottom: 0;
      left: 0;
      right: 0;
      //padding: 2.4rem;
      position: fixed;
      padding: 1rem;
      top: unset;
      height: 10%;
      justify-content: space-between;
      box-shadow: 0 2px 15px rgba(0,0,0,.1);
      //height: 50vh;
      z-index: 250;
      width: 100%;
      flex: 1;
      border-radius: unset;
    }
    &-list{
      list-style: none;
      display: flex;
      flex-direction: column;
      //margin-left: ;
      //margin-top: 4rem;
      @include respond(tabPort){
        flex-direction: row;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0 0;
      }
      &-item{
        margin: 2rem 0;
        @include respond(tabPort) {
          margin: 1.2rem 0;
          //background-color: #396dc4;
        }
        &-link{
          text-decoration: none;

          div{
            display: flex;
            align-items: center;
            transition: all .35s ease-out;
            padding: 1.5rem;
            @include respond(tabPort){
              flex-direction: column;
            }
            span,i{
              font-size: 1.8rem;
              margin-left: 1rem;
              //color: var(--text);
              color: #f7f7f7;
              @include respond(tabPort){
                //display: none;
                font-size: 1.45rem;
              }

            }
            svg{
              font-size: 2.8rem;
              //color: var(--text);
              color: #f7f7f7;
              @include respond(tabPort){
                //display: none;
                //font-size: 1.45rem;
              }
            }
          }
          &:hover{
            div{
              fill: var(--prim);
              transform: scale(1.1);
              span{
                color: var(--prim);
                //font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
.dashboard_content-body{
  padding:  4rem;
  width: 100%;
  background-color: white;

  @include respond(tabPort){
    padding:  2rem;

  }
}