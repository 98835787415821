.btn{&,
  &:link{
    padding: 1.5rem;
    font-size: 2.4rem;
    text-decoration: none;
  display: inline-block;
    border: none;
  transition: all .35s ease-out;
  }
  &-dark{
     background-color: var(--darkk);
    color: white;
    &:hover{
      background-color: var(--dark);
    }
  }
  &-primary{
    background-color: #79E76C;
    &:hover{
      color: white;
      background-color: var(--primary-dark);
    }
  }
  &-sec{
    background-color: #0a74dd;
    color: white;
    &:hover{
      background-color: #0d9bf6;
    }
  }
  &-red{
    background-color: #ffc5db;
    color: #f62b54;
    &:hover{
      color: white;
      background-color: #ec5080;
    }
  }
}