.faq-section{
  &-item{
   &:not(:last-child){
     margin-bottom: 2rem;
   }
    transition: all .35s ease-in-out;
    &-top{
      padding: 2.5rem;
      background-color: var(--dark-alt2);
      color: #f7f7f7;
      display: flex;
      justify-content: space-between;


      &-icon{

        span{
            margin-left: -4rem;
            position: relative;
          display: block;
            &:after,&:before{
             display: block;
              width: 4rem;
              height: 1rem;
             background-color: var(--prim);
              position: absolute;
              content: "";
              transition: all .35s cubic-bezier(1,.25,.78,1);
            }
          &:after{
            transform: rotate(90deg) translate(-30%,0);
            background-color: var(--prim);
          }


        }
      }
    }
    &-body{
      padding: 2rem;
      background-color: var(--dark-alt);
      color: #f7f7f7;
      //animation: slideInDown .35s ease-in;
      transition: all .35s ease;
    }
  }
}
#faq-section-item-top-icon{
  display: none;
}

#faq-section-item-top-icon:checked ~ .faq-section-item-top-icon span:after{
  opacity: 0;
}