.hero{
  height: 100vh;
  padding: 1.5rem;
  background-image: url("../../assets/hero.png");
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  border-bottom: 8px solid var(--primary);
  justify-content: space-between;
  @include respond(tabPort){
    background-position: bottom right;
  }
  .top-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }
  .hero-center{
    display: flex;
    justify-content: center;
    .lgText{
      font-size: 7.2rem ;
      color: white;
      position: relative;
      .leaf{
        position: absolute;
        top: -1.4rem;
        margin-left: -.2rem;
      @include respond(phone){
        &Icon{

            height: 4.8rem;
        }
      }
      }
    }
  }
  .bottom-nav{
    display: flex;
    justify-content: space-between;
    .datebox{
      color: white;
      text-shadow: 0 4px 8px rgba(0,0,0,.25);
      &Month{
        font-size:1.8rem;
        font-weight: normal;
      }
      &Day{
        font-size: 2.4rem;
      }
      &SubDay{
        font-size: 1.8rem;
        font-weight: normal;
      }
    }
    .nav{
      display: flex;
      list-style: none;
      @include respond(phone){
        flex-wrap: wrap;
        justify-content:flex-end;
      }
      li{
        font-size: 1.8rem;
        padding: 0 1.8rem;
        a{
          text-decoration: none;
          color: white;
        }
      }
    }

  }
}
.logo{
  height: 8rem;
}

.nextBtn{
  position: absolute;
  //bottom: 2rem;
  top: 75%;
  left: 50%;
  &Icon{
    height: 10rem;
  }
  transform: translateX(-25%) translateY(-75%);
}
.centerItemsAbs{
  position: absolute;
  top: 100%;
  left: 50%;
  color: white;
  //background-color: red;
  width: 100%;
  transform: translate(-50%,15vh);


}

.trY4{
  transform: translateY(-15%);
  img{
    height:50%
  }

}

.curve{
  width: 50%;
}
.about{
  position: relative;
  &:before{
    content: "";
    height: 6rem;
    position: absolute;
    background-color: var(--dark);
    width: 1000px;
    top: 0;
  }
  overflow-x: hidden;
  &TitleSection{
    overflow-y: visible;
    height: 50vh;

    &Center{
      z-index: 2;
      position: relative;
      height: auto;
    }
    &:before{
      position: absolute;
      content: "";
      width: 2000px;
      top: 6rem;
      height: 40vh;
      left: -1000px;

      //left: -1000px;
      background-color: var(--primary-lime);
    }

    &:after{
      position: absolute;
      content: "";
      width: 1000%;
      height: 65vh;
      left: 25%;
      //left: -1000px;
      background-image: linear-gradient(#262626,#131313);
    }
  }
  &Paragraph{
    padding: 4rem 0;
    margin-top: 8rem;
    @include respond(tabLand){
      margin-top: 40rem;
    }
  }
}
.quote{
  margin-top: 16rem;
    @include respond(tabLand){
    z-index: 4;
    position: absolute;
    text-shadow: 0 8px 20px rgba(0,0,0,.5);
    color: white;

  }
}
.nextPage{
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
}
.story-gallery{
  background-color: var(--darkk);
  height: 100vh;
  padding: 1.5rem;
  //overflow-y: hidden;
  position: relative;
  @include respond(tabPort){
    overflow: hidden;
    padding: 1rem;
    width: 95%;
  }
}
.story-text{
 padding: 2rem;
  position: relative;
  height: 100vh;
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    height: 1rem;
    background-color: var(--primary);
  }
}
.scrollGrid{
  overflow-y: hidden;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  width: 100%;
  &::-webkit-scrollbar{
    display: none;
  }
  @extend %scrollbars;
  &1_2{
    margin: 0 1rem;
  }
  @include respond(tabPort){
    width: 100%;
  }
}

.rt90{
  transform: rotate(90deg);
}
.scrollCard{
  margin: 1.5rem 0;
  background-position: bottom;
  background-size: cover;
  //display: block;
  padding: 1.5rem;
  height: 35rem;
  min-height: 50%;
  width: 29.9rem;
  transition: transform 5s ease-in-out ;
  &:hover{
    transform: rotate(15deg);
    z-index: 2;
  }
}

.anime1{
  animation: slide 15s reverse linear infinite;
}

.anime2{
  animation: slide 18s linear  infinite;
  animation-delay: .2s;
}

@keyframes slide {

  50% {
    transform: translate3d(0, -620px, 0); /* The image width */
  }
}

.foopCard{
  background-color: var(--primary-light);
  padding: 2rem;
  position: relative;
  &IconL{
    position: absolute;
    top: -4rem;
    left: -4.5rem;
    svg{
      height: 8rem;
    }
  }
  &IconR{
    position: absolute;
    bottom: -4.5rem;
    right: -4.5rem;
  }
}

.objSm{
  background-color: var(--dark);
  padding: 2rem;
  position: relative;
  min-height: 50rem;
  &IconL{
    position: absolute;
    bottom: -4rem;
    left: -4.2rem;
    svg{
      height: 8rem;
    }
  }
  &IconR{
    position: absolute;
    top: -4.2rem;
    right: -4.2rem;

  }
}

.objLg{
  padding: 2.5rem;
  background-color: var(--darkk);

  //height: 70vh;
  &Img{

    padding: 1rem;

   &Top{
     transform: translateY(-15%);
   }
    &Bottom{
      transform: translateY(115%);
    }
  }
}
.obj{
  position: relative;
  background-color: #f7f7f7;
  margin-bottom: 8rem;
  &:after{
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 23%;
    height: 1rem;
    background-color: var(--primary);
  }
}
.partnersSection{
  background-color: var(--darkk);
  padding: 2rem;
  margin-top: 24rem;
  .center-all{
    margin-top: 4rem;
  }
  &Card{
      padding: 2rem;
      min-height: 25rem;
     margin: 0 1rem;
    background-color: #f7f7f7;
  }
}
footer{
  background-color: var(--dark);
  border-top: 12px solid var(--primary);
  padding-bottom: 4rem;
  @include respond(tabPort){
    overflow-x: hidden;
  }

}