.navigation{
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  &-list{
    display: flex;
    justify-content: space-between;
    list-style: none;
    position: relative;
    flex: .4;
    &:after{
      content: "";
      width: 75%;
      height: .6rem;
      background-color: var(--primary);
      position: absolute;
      bottom: -4rem;
      right: 0;
    }
    &-item{
      font-size: 1.8rem;
      &:not(:last-child){
        margin-right: 1rem;
      }
      a{
        text-decoration: none;
        color: black;
        &:hover{
          color: var(--primary);
        }
      }

    }
  }
}