@import "../base/mixin";
.authBox{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  min-height: 100vh;
  background-size: cover;
  &-card{
    width: 50rem;
    padding: 2.5rem;
    margin: 2rem 0;
    background-color: white;
    //box-shadow: 0 4px 20px rgba(0,0,0,.2);
    @include respond(tabPort){
      width: 80%;
    }
    @include respond(phone){
      width: 90%;
    }
  }
}