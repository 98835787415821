.table-holder{
   width: 100%;
  //padding: 1rem;
  //background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1));
  //background-color: rgba(0,0,0,.2);
  //backdrop-filter: blur(20px);
  overflow-x: auto;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width:none;
}
.light-table{
  text-align: left;
  width: 100%;
  background-color: inherit;
  border-collapse: collapse;
  thead{
    background-color: var(--dark);
    tr{

        th{
          font-size: 1.8rem;
          border-bottom: 1px solid #f7f7f7;
          padding: 2rem;
          color: #f7f7f7;



        }

    }
  }
  tbody{
    tr{
      transition: all .3s ease-out;
      background-color: #f7f7f7;
      td{
        padding: 2rem;
        font-size: 1.8rem;
        transition: all .35s ease-out;

        h6,h2,h3,h4,h5,h1,p{
          font-size: 1.4rem;
        }
      }
      &:hover{
        background-color: #e5e5e5;
      }
    }
     }


}


th,td{

  text-align: left;
  vertical-align: top;
}


.emptyTable{
background-color: #f7f7f7;
  //collapse: columns;
  font-size: 4.8rem;
  //width: 1000%;
  color: #35495e;
  padding: 1.5rem;
  border-collapse: collapse
}
.tableMsg{
   position: absolute;
   top: 50%;
  left: 50%;
  width: 50%;
  padding: 5rem;
  background-color: #f7f7f7;
  transform: translate(-50%,-50%);
  border-radius: 2rem;
  font-size: 2.4rem;
  text-align: center;
 p{
   color: #37A050 !important;

 }
}

.tr-nodata{
  background-color: #e5e5e5;
  width: 100%;
}

.emptyTable{
  background-color: #f7f7f7;
  //collapse: columns;
  font-size: 4.8rem;
  //width: 1000%;
  color: #1f2937;
  padding: 1.5rem;
  border-collapse: collapse
}

.emMd{
  background-color: #262626;
  padding: .8rem;
  color: #d4af37;
  font-size: 1.6rem;
}