
@import "../base/mixin";
.yorfan-header{
  height: 100vh;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  background-color: #333333;
  //background-image: linear-gradient(45deg,rgba(196, 148, 55, 0.8),rgba(0,0,0,.3));
  background-attachment: fixed;
  @include respond(tabPort){
    min-height: 115vh;
  }
  &-text{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    width: 80%;
    //text-shadow: 0 .4px 8px rgba(0,0,0,.3);
    @include respond(tabPort){
      top: 55%;
    } @include respond(phone){
      top: 60%;
    }
    h1{
      font-family: 'Raleway', 'Poppins', sans-serif;
      //color:transparent;
      //-webkit-background-clip: text;
      //background-image: linear-gradient(25deg,var(--gold),#f7c300);
    }
  }
}
.yorfanfooter-section{
  background-color: var(--dark);
  padding: 4rem;
}
.yorfan-nav{
  display: flex;
  padding: 2.5rem 0;
  width: 80%;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  border-bottom: 2px solid var(--gold);
  @include respond(tabPort){
    width: 90%;
  }
  &-list{
    display: flex;
    list-style: none;
    &-item{
      font-size: 1.8rem;
      color: white;
      &:hover{
        color: var(--gold);
      }
     &:not(:last-child){
       margin-right: 2rem;
     }
      a{
        font-size: 1.8rem;
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
.yorfan_about{
  padding: 4rem;
  width: 100%;
  &-item{
    background-color:#f7f7f7;
    padding: 1.5rem;
    position: relative;
    margin: 2rem 0;
    @include respond(tabPort){
      width: 100%;
      height: 100vh;
    }
   &--1{
     position: absolute;
     width: 50%;
     left:-1rem;
     transition: all .35s ease-in-out;
     &:hover{
       border: 8px solid var(--gold);
       z-index: 2;
     }
   }
   &--2{
     position: absolute;
     width: 50%;
     right: -1rem;
     transition: all .35s ease-in-out;
     &:hover{
       border: 8px solid var(--gold);
       z-index: 2;
     }
   }
   &--3{
     &:hover{
       border: 8px solid var(--gold);
       z-index: 2;
     }
     transition: all .35s ease-in-out;
     position: absolute;
     width: 50%;
     bottom: -2rem;
     left: 50%;
     transform: translate(-50%,50%);
   }
  }
}

.goals{
  padding: 4rem 0;
  &Card{
    border:2px solid #f7f7f7;
    padding: 4rem;
    text-align: center;
    transition: all .35s ease-in-out;
    &:hover{
      background-color: #f7f7f7;
      border-left: 4px solid var(--gold);
      transform: translateY(-.5rem) scale(1.1);
    }
  }
}