.h1{
  font-size: 7.2rem;
}
.h2{
  font-size: 6.4rem;
}
.h3{
  font-size: 4.8rem;
}
.h4{
  font-size: 3.6rem;
}
.h5{
  font-size: 2.4rem;
}
.h6{
  font-size: 1.8rem;
}
.h7{
  font-size: 1.6rem;
}
.h8{
  font-size: 1.4rem;
}
.h9{
  font-size: 1.2rem;
}
.redText{
  color: #f62b54;
}


.post-h{
  font-size: 3.6rem;
  @include respond(phone){
    font-size: 2.8rem;
  }
}
.greyText{
  color: #b3b3b3;
}
.greyText2{

  color: #f7f7f7;
}
.text_left{
  text-align: left;
  align-self: flex-start;
}
.blueText{
  color: #5c8af1;
}
.yellowText{
  color: #ff9a00;
}
.gold{
  color: var(--gold);
}
.bold{
  font-weight: bold;
}
.link_article{
  font-size: 1.5rem;
  color: #0a9cdd;
}  list_od{
  list-style: none;
  color: #262626;
  li{
    font-size: 1.8rem;
    margin: 1.8rem 0;
  }
}
.text_right{
  text-align: right;
}
.no-deco{
  text-decoration: none;
}
.orangeText{
  color: #473e2f;
}
.whiteText{
  color: white;
}
.subtitle1{
  font-size: 1.4rem;
}
.hashEm{
  background-color: #ffeec4;
  color: #eea507;
  display: inline-block;
  font-size: 1.6rem;
  padding: .5rem;
  margin: 0 .5rem ;
}
.shadowText{
  text-shadow: 0 2px 40px rgba(0,0,0,.8);
}

.success{
  color: #29e55f;
}
.danger{
  color: #f62b54;
}

.display_block{
  display: block;
}

.black{
  color: #35495e;
}

.overflowText{
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  @include respond(tabPort){
    max-width: 80%;
  }
}

.gradientText{
  -webkit-background-clip: text;
  background-image: conic-gradient(#0a74dd, #0d9bf6);
  color: transparent;
  white-space: nowrap;
}
.lightColor{
  color: #f7f7f7!important;
}
.list{
  list-style-position:inside;

  li{
    margin: 1rem 0;
    font-size: 1.8rem;

  }
}
.link{
  color: var(--primary);
  font-weight: bold;
  text-decoration: none;
}

.centerText{
  text-align: center;
}
.textJust{
  text-align: justify;
}
.toUpper{
  text-transform: uppercase;
}
.nowhitespace{
  white-space: nowrap;
}
.sec_type{
  font-family: 'Open Sans','Poppins',sans-serif;
}