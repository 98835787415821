@import "../base/mixin";

.gallery_img_box{
   padding: 2rem;
  background-color: var(--darkk);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-item{
    position: relative;
    margin: 1rem;
    overflow-y: hidden;
    width: 100%;
    flex: .3;
    background-color: var(--primary-lime);
    padding: 2rem;
    min-height: 30rem;
    background-size: cover;
    background-repeat: no-repeat;

    min-width: 25rem;
    @include respond(tabPort){
      width: 100%;
      flex: 1;
      min-width: 100%;
      margin-left: unset;
    }
    &:hover &-desc{
      opacity: 1;
    }

    &-desc{
      transition: all .35s ease-in;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 2rem;
      height: 100%;
      background-color: rgba(38, 38, 38, 0.75);
      backdrop-filter: blur(8px);
      animation: slideInDown  .35s ease-in forwards;
    }

  }
}
@keyframes slideInDown {
  0%{
    transform: translateY(500px);
  }
}

.galleryVideos{

  display: flex;
  flex-wrap: wrap;
  //justify-content: space-evenly;
  align-items: flex-start;
}
.galleryVideo{
   background-color: var(--darkk);
  margin-left: 2rem;
  padding: 1rem;
  position: relative;
  min-height: 35rem ;
  flex: .3;
  min-width: 25rem;
  @include respond(tabPort){
    width: 100%;
    flex: 1;
    margin-top: 1rem;
    margin-left: unset;
    min-width: 100%;
  }
  &-play{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &-h{
    color: var(--prim);
  }
  overflow-x: hidden;
  &-bottom{
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    margin-left: 2rem;
     &:after{
       content: "";
       position: absolute;
       bottom: -1.5rem;
       width: 100%;
       height: 1rem;
       background-color: var(--prim);
     }
  }
}