@import "../base/mixin";

.blogCard{
  background-color: var(--dark-alt);
  padding: 1.5rem;
  position: relative;
  flex: .3;
  min-height: 25rem;
  min-width: 30rem;
  margin: 1.5rem;
  transition: all .35s ease;
  &:hover{
    transform: translateY(-5%);
  }
  @include respond(tabPort){
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
  &-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(5px);
    padding: 1rem;
  }
}
.postSection{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.post_image-box{
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}
.post_img{
   width: 30%;
  height: auto;
  flex: 1;
  display: inline-block;
  margin: 1rem;
}

.post-box{
  h1{
    font-size: 7.2rem;
  }
  h2{
    font-size: 6.4rem;
  }
  h3{
    font-size: 4.8rem;
  }
  h4{
    font-size: 3.6rem;
  }
  h5{
    font-size: 2.4rem;
  }
  h6{
    font-size: 1.8rem;
  }
}